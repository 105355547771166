exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-de-about-js": () => import("./../../../src/pages/de/about.js" /* webpackChunkName: "component---src-pages-de-about-js" */),
  "component---src-pages-de-contacts-js": () => import("./../../../src/pages/de/contacts.js" /* webpackChunkName: "component---src-pages-de-contacts-js" */),
  "component---src-pages-de-diagnostics-js": () => import("./../../../src/pages/de/diagnostics.js" /* webpackChunkName: "component---src-pages-de-diagnostics-js" */),
  "component---src-pages-de-endodontics-js": () => import("./../../../src/pages/de/endodontics.js" /* webpackChunkName: "component---src-pages-de-endodontics-js" */),
  "component---src-pages-de-fillings-js": () => import("./../../../src/pages/de/fillings.js" /* webpackChunkName: "component---src-pages-de-fillings-js" */),
  "component---src-pages-de-implants-js": () => import("./../../../src/pages/de/implants.js" /* webpackChunkName: "component---src-pages-de-implants-js" */),
  "component---src-pages-de-index-js": () => import("./../../../src/pages/de/index.js" /* webpackChunkName: "component---src-pages-de-index-js" */),
  "component---src-pages-de-orthodontics-js": () => import("./../../../src/pages/de/orthodontics.js" /* webpackChunkName: "component---src-pages-de-orthodontics-js" */),
  "component---src-pages-de-periodontology-js": () => import("./../../../src/pages/de/periodontology.js" /* webpackChunkName: "component---src-pages-de-periodontology-js" */),
  "component---src-pages-de-prophylaxis-js": () => import("./../../../src/pages/de/prophylaxis.js" /* webpackChunkName: "component---src-pages-de-prophylaxis-js" */),
  "component---src-pages-de-prosthodontics-js": () => import("./../../../src/pages/de/prosthodontics.js" /* webpackChunkName: "component---src-pages-de-prosthodontics-js" */),
  "component---src-pages-de-services-js": () => import("./../../../src/pages/de/services.js" /* webpackChunkName: "component---src-pages-de-services-js" */),
  "component---src-pages-de-surgery-js": () => import("./../../../src/pages/de/surgery.js" /* webpackChunkName: "component---src-pages-de-surgery-js" */),
  "component---src-pages-diagnostics-js": () => import("./../../../src/pages/diagnostics.js" /* webpackChunkName: "component---src-pages-diagnostics-js" */),
  "component---src-pages-endodontics-js": () => import("./../../../src/pages/endodontics.js" /* webpackChunkName: "component---src-pages-endodontics-js" */),
  "component---src-pages-fillings-js": () => import("./../../../src/pages/fillings.js" /* webpackChunkName: "component---src-pages-fillings-js" */),
  "component---src-pages-fr-about-js": () => import("./../../../src/pages/fr/about.js" /* webpackChunkName: "component---src-pages-fr-about-js" */),
  "component---src-pages-fr-contacts-js": () => import("./../../../src/pages/fr/contacts.js" /* webpackChunkName: "component---src-pages-fr-contacts-js" */),
  "component---src-pages-fr-diagnostics-js": () => import("./../../../src/pages/fr/diagnostics.js" /* webpackChunkName: "component---src-pages-fr-diagnostics-js" */),
  "component---src-pages-fr-endodontics-js": () => import("./../../../src/pages/fr/endodontics.js" /* webpackChunkName: "component---src-pages-fr-endodontics-js" */),
  "component---src-pages-fr-fillings-js": () => import("./../../../src/pages/fr/fillings.js" /* webpackChunkName: "component---src-pages-fr-fillings-js" */),
  "component---src-pages-fr-implants-js": () => import("./../../../src/pages/fr/implants.js" /* webpackChunkName: "component---src-pages-fr-implants-js" */),
  "component---src-pages-fr-index-js": () => import("./../../../src/pages/fr/index.js" /* webpackChunkName: "component---src-pages-fr-index-js" */),
  "component---src-pages-fr-orthodontics-js": () => import("./../../../src/pages/fr/orthodontics.js" /* webpackChunkName: "component---src-pages-fr-orthodontics-js" */),
  "component---src-pages-fr-periodontology-js": () => import("./../../../src/pages/fr/periodontology.js" /* webpackChunkName: "component---src-pages-fr-periodontology-js" */),
  "component---src-pages-fr-prophylaxis-js": () => import("./../../../src/pages/fr/prophylaxis.js" /* webpackChunkName: "component---src-pages-fr-prophylaxis-js" */),
  "component---src-pages-fr-prosthodontics-js": () => import("./../../../src/pages/fr/prosthodontics.js" /* webpackChunkName: "component---src-pages-fr-prosthodontics-js" */),
  "component---src-pages-fr-services-js": () => import("./../../../src/pages/fr/services.js" /* webpackChunkName: "component---src-pages-fr-services-js" */),
  "component---src-pages-fr-surgery-js": () => import("./../../../src/pages/fr/surgery.js" /* webpackChunkName: "component---src-pages-fr-surgery-js" */),
  "component---src-pages-implants-js": () => import("./../../../src/pages/implants.js" /* webpackChunkName: "component---src-pages-implants-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-orthodontics-js": () => import("./../../../src/pages/orthodontics.js" /* webpackChunkName: "component---src-pages-orthodontics-js" */),
  "component---src-pages-periodontology-js": () => import("./../../../src/pages/periodontology.js" /* webpackChunkName: "component---src-pages-periodontology-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-prophylaxis-js": () => import("./../../../src/pages/prophylaxis.js" /* webpackChunkName: "component---src-pages-prophylaxis-js" */),
  "component---src-pages-prosthodontics-js": () => import("./../../../src/pages/prosthodontics.js" /* webpackChunkName: "component---src-pages-prosthodontics-js" */),
  "component---src-pages-pt-about-js": () => import("./../../../src/pages/pt/about.js" /* webpackChunkName: "component---src-pages-pt-about-js" */),
  "component---src-pages-pt-contacts-js": () => import("./../../../src/pages/pt/contacts.js" /* webpackChunkName: "component---src-pages-pt-contacts-js" */),
  "component---src-pages-pt-diagnostics-js": () => import("./../../../src/pages/pt/diagnostics.js" /* webpackChunkName: "component---src-pages-pt-diagnostics-js" */),
  "component---src-pages-pt-endodontics-js": () => import("./../../../src/pages/pt/endodontics.js" /* webpackChunkName: "component---src-pages-pt-endodontics-js" */),
  "component---src-pages-pt-fillings-js": () => import("./../../../src/pages/pt/fillings.js" /* webpackChunkName: "component---src-pages-pt-fillings-js" */),
  "component---src-pages-pt-implants-js": () => import("./../../../src/pages/pt/implants.js" /* webpackChunkName: "component---src-pages-pt-implants-js" */),
  "component---src-pages-pt-index-js": () => import("./../../../src/pages/pt/index.js" /* webpackChunkName: "component---src-pages-pt-index-js" */),
  "component---src-pages-pt-orthodontics-js": () => import("./../../../src/pages/pt/orthodontics.js" /* webpackChunkName: "component---src-pages-pt-orthodontics-js" */),
  "component---src-pages-pt-periodontology-js": () => import("./../../../src/pages/pt/periodontology.js" /* webpackChunkName: "component---src-pages-pt-periodontology-js" */),
  "component---src-pages-pt-prophylaxis-js": () => import("./../../../src/pages/pt/prophylaxis.js" /* webpackChunkName: "component---src-pages-pt-prophylaxis-js" */),
  "component---src-pages-pt-prosthodontics-js": () => import("./../../../src/pages/pt/prosthodontics.js" /* webpackChunkName: "component---src-pages-pt-prosthodontics-js" */),
  "component---src-pages-pt-services-js": () => import("./../../../src/pages/pt/services.js" /* webpackChunkName: "component---src-pages-pt-services-js" */),
  "component---src-pages-pt-surgery-js": () => import("./../../../src/pages/pt/surgery.js" /* webpackChunkName: "component---src-pages-pt-surgery-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-surgery-js": () => import("./../../../src/pages/surgery.js" /* webpackChunkName: "component---src-pages-surgery-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

